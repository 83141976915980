// cspell:disable
import {Method, SimpleAPI} from '../../SimpleAPI'

type ListPublicInvestorTableItemsResponse = {
  investors: PublicInvestorTableItem[]
}
type PublicInvestorTableItem =
  | PublicInvestorTableItemInvestor
  | PublicInvestorTableItemFund

type PublicInvestorTableItemInvestor = {
  tag: 'publicInvestorTableItemInvestor'
  contents: InvestorTableItem
}

type PublicInvestorTableItemFund = {
  tag: 'publicInvestorTableItemFund'
  contents: FundTableItem
}

type InvestorTableItem = {
  id: string
  firstName: string
  lastName?: string
  position?: string
  industries: string[]
  geographies: string[]
  bannedIndustries: string[]
  stages: string[]
  type: string
  minCheckSize?: number
  maxCheckSize?: number
  imageUrl: string
  callsign: string
  leadsRounds: InvestorLeadsRound
  hasAllStages: boolean
}

type FundTableItem = {
  id: string
  fundName: string
  foundingYear: number
  industries: string[]
  geographies: string[]
  bannedIndustries: string[]
  stages: string[]
  type: string
  minCheckSize?: number
  maxCheckSize?: number
  imageUrl: string
  callsign: string
  leadsRounds: InvestorLeadsRound
  hasAllStages: boolean
}

type InvestorLeadsRound = 'yes' | 'no' | 'sometimes' | 'prefersTo'

export class InvestorDatabaseGetPublicInvestorItems extends SimpleAPI<ListPublicInvestorTableItemsResponse> {
  revalidate = 'on-demand' as const
  method = Method.POST

  getPath() {
    return '/investor-db/get-public-investor-items'
  }

  protected async getMockResponse() {
    return {investors: []}
  }
}
