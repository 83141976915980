import CheckCallsign from './endpoints/CheckCallsign'
import {ClientSanityLoggedOut} from './endpoints/ClientSanityLoggedOut'
import {ContactTreasury} from './endpoints/ContactTreasury'
import {FEMReferralAttribution} from './endpoints/FEMReferralAttribution'
import {Geolocate} from './endpoints/Geolocate'
import {HelpCenterGetArticles} from './endpoints/HelpCenterGetArticles'
import {InvestorDatabaseGetPublicInvestorItems} from './endpoints/InvestorDatabaseGetPublicInvestorItems'
import {ListInterestRates} from './endpoints/ListInterestRates'
import {NewsletterSubscribe} from './endpoints/NewsletterSubscribe'
import PartnerSignupOffers from './endpoints/PartnerSignupOffers'

export const api = {
  listInterestRates: new ListInterestRates(),
  newsletterSubscribe: new NewsletterSubscribe(),
  contactTreasury: new ContactTreasury(),
  geolocate: new Geolocate(),
  clientSanityLoggedOut: new ClientSanityLoggedOut(),
  femReferralAttribution: new FEMReferralAttribution(),
  investorDatabaseGetPublicInvestorItems:
    new InvestorDatabaseGetPublicInvestorItems(),
  helpCenterGetArticles: new HelpCenterGetArticles(),
  partnerSignupOffers: new PartnerSignupOffers(),
  checkCallsign: new CheckCallsign(),
}
