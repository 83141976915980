// cspell:disable
import {Method, SimpleAPI} from '../../SimpleAPI'

/**
 * This is the client-only version of the sanity logged out endpoint. This should be used
 * if the query is client specific (i.e. it is based on localStorage and is not server rendered)
 *
 * For server-rendered stuff like InvestorDB, use the non-client version
 */
export class ClientSanityLoggedOut extends SimpleAPI<any, any> {
  revalidate = 'client-only' as const
  method = Method.POST

  getPath() {
    return '/sanity/logged-out'
  }

  protected async getMockResponse(): Promise<any> {
    return {}
  }
}
